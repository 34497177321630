import React, { useState, useEffect } from 'react';

const CountMaintenancePlans = ({ filters }) => {
  const [number, setNumber] = useState(0);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchCount = async () => {
      try {
        const queryParams = new URLSearchParams({
          filters: JSON.stringify(filters), // Convertir filters a cadena JSON
        });

        const response = await fetch(
          `/api/v2/maintenance_plans/set_collection?${queryParams}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setNumber(data.total || 0);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchCount();
  }, [filters]);

  return (
    <div>
      {(
        number > 0 && (
          <span id="test" className="badge badge-pill badge-warning">
            {number}
          </span>
        )
      )}
    </div>
  );
};

export default CountMaintenancePlans;
