import React from 'react'

const Buttons = ({permissions, user, indicator, portal}) => {
  let editor = false
  let follow = false
  let semaphores = false
  let goalMeasures = false
  let measures = false
  let charts = (indicator.semaphores?.length > 0)
  let restore = false
  let finished = false
  let obsoletes = false
  let deleteButton = false
  let restoreTwo = false
  let responsible_permission = false

  console.log(portal.sesur)
  if (portal.clinizad || portal.sesur) {
    follow = permissions.admin_sg 
    editor = permissions.admin_sg || user.advisor || user.admin || permissions.permissions
    semaphores = permissions.admin_sg || user.advisor || user.admin || permissions.permissions
    goalMeasures = (indicator.semaphores?.length > 0) && (permissions.user_manager || permissions.admin_sg)
    measures = (indicator.semaphores?.length > 0) && (user.id === indicator.responsible_id) || (user.id === indicator.metrics_responsible_id) || permissions.admin_sg || permissions.user_manager || user.advisor
    restore = permissions.user_manager || permissions.admin_sg
    finished = permissions.user_manager || permissions.admin_sg
    obsoletes = permissions.admin_sg || user.admin
    deleteButton = permissions.admin_sg || user.admin
    restoreTwo = permissions.admin_sg || user.admin
  } else if (portal.upl) {
    follow = permissions.admin_sg
    editor = user.admin || (user.id === indicator.responsible_id) || (user.id === indicator.metrics_responsible_id) || permissions.admin_sg || permissions.user_manager || user.advisor || permissions.permissions
    semaphores = user.admin || (user.id === indicator.responsible_id) || (user.id === indicator.metrics_responsible_id) || permissions.admin_sg || user.advisor || permissions.permissions
    goalMeasures = editor && (indicator.semaphores?.length > 0)
    measures = editor && (indicator.semaphores?.length > 0)
    restore = (user.id === indicator.responsible_id) || permissions.user_manager || permissions.admin_sg
    finished = restore
    obsoletes = (user.id === indicator.responsible_id) || permissions.admin_sg || permissions.user_manager
    deleteButton = obsoletes
    restoreTwo = obsoletes
  } else if (portal.montagas) {
    responsible_permission = (user.id === indicator.responsible_id) || (user.id === indicator.metrics_responsible_id) || user.advisor || permissions.permissions
    follow = permissions.all_user
    console.log(responsible_permission)
    editor = user.admin || permissions.admin_sg || permissions.user_manager
    semaphores = user.admin || permissions.admin_sg || permissions.user_manager
    goalMeasures =user.admin || permissions.admin_sg || permissions.user_manager
    measures = (responsible_permission || editor) && (indicator.semaphores?.length > 0)
    restore = user.admin || permissions.admin_sg
    finished = restore
    obsoletes = permissions.admin_sg || permissions.user_manager
    deleteButton = obsoletes
    restoreTwo = obsoletes
  } else {
    follow = permissions.admin_sg
    editor = user.admin || (user.id === indicator.responsible_id) || (user.id === indicator.metrics_responsible_id) || permissions.admin_sg || permissions.user_manager || user.advisor || permissions.permissions
    semaphores = user.admin || (user.id === indicator.responsible_id) || (user.id === indicator.metrics_responsible_id) || permissions.admin_sg || permissions.user_manager || user.advisor || permissions.permissions
    goalMeasures = editor && (indicator.semaphores?.length > 0)
    measures = editor && (indicator.semaphores?.length > 0)
    restore = permissions.admin_sg || permissions.user_manager || user.advisor
    finished = permissions.admin_sg || permissions.user_manager || user.advisor
    obsoletes = (user.admin || (user.id === indicator.responsible_id) || (user.id === indicator.metrics_responsible_id) || permissions.admin_sg || permissions.user_manager)
    deleteButton = obsoletes
    restoreTwo = obsoletes
  }

  const handleFinishedClick = async (event) => {
    event.preventDefault();

    const confirmed = window.confirm('¿Está seguro de enviar a finalizados?');
    if (confirmed) {
      try {
        fetch(`/indicators/${indicator.id}/finished?update_to=true`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        }).then((response) => { return response.json() })
        .then(window.location.href = '/indicators/list')

      } catch (error) {
        console.error('Error al enviar a finalizados:', error);
      }
    }
  }

  const handleRestoreClick = async (event) => {
    event.preventDefault();

    const confirmed = window.confirm('¿Está seguro de restaurar a indicadores activos?');
    if (confirmed) {
      try {
        fetch(`/indicators/${indicator.id}/finished?update_to=false`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        }).then((response) => { return response.json() })
        .then(window.location.href = '/indicators/list')
      } catch (error) {
        console.error('Error al restaurar:', error);
      }
    }
  }

  const handleObsoletes = async (event) => {
    event.preventDefault();

    const confirmed = window.confirm('¿Está seguro de enviar a obsoletos?');
    if (confirmed) {
      try {
        fetch(`/indicators/${indicator.id}/obsolete.to_obsolete`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        }).then((response) => { return response.json() })
        .then(window.location.href = '/indicators/list')
      } catch (error) {
        console.error('Error al enviar a finalizados:', error);
      }
    }
  }

  const handleDelete = async (event) => {
    event.preventDefault();

    const confirmed = window.confirm('¿Está seguro de eliminar el indicador?');
    if (confirmed) {
      try {
        fetch(`/indicators/${indicator.id}`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        }).then((response) => { return response.json() })
        .then(window.location.href = '/indicators/list')
      } catch (error) {
        console.error('Error al borrar indicador', error);
      }
    }
  }

  const handleRestore = async (event) => {
    event.preventDefault();

    const confirmed = window.confirm('¿Está seguro de eliminar el indicador?');
    if (confirmed) {
      try {
        fetch(`/indicators/${indicator.id}/obsolete.restore`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        }).then((response) => { return response.json() })
        .then(window.location.href = '/indicators/list')
      } catch (error) {
        console.error('Error al restaurar el indicador', error);
      }
    }
  }

  return(
    <React.Fragment>
      <div className='d-flex mb-1 ml-2'>
       <div className='my-tooltip-right'>
          <a className={`btn btn-sm btn-outline-success mr-1 hover-blank ${follow || editor ? '' : 'disabled'}`}
             href={`/follow_indicators?id=${indicator.id}`}>
            <i className='fas fa-check-double text-success'></i>
          </a>
          <span className="tooltiptext">Seguimientos</span>
        </div>

        <div className='my-tooltip-right'>
          <a className={`btn btn-sm btn-outline-success mr-1 hover-blank ${editor ? '' : 'disabled'}`}
             href={`/indicators/${indicator.id}/edit`}>
            <i className='fas fa-edit text-success'></i>
          </a>
          <span className="tooltiptext">Editar</span>
        </div>

        <div className='my-tooltip-right'>
          <a className={`btn btn-sm btn-outline-success mr-1 ${semaphores ? '' : 'disabled'}`}
             href={`/indicators/${indicator.id}/semaphore`}>
            🚦
          </a>
          <span className="tooltiptext">Configuración de Semáforo</span>
        </div>

        <div className='my-tooltip-right'>
          <a className={`btn btn-sm btn-outline-primary mr-1 hover-blank ${goalMeasures ? '' : 'disabled'}`}
             href={`/indicators/${indicator.id}/goal_measures`}>
            <i className='fas fa-bullseye text-primary'></i>
          </a>
          <span className="tooltiptext">Agregar Meta</span>
        </div>

        <div className='my-tooltip-right'>
          <a className={`btn btn-sm btn-outline-primary mr-1 ${measures ? '' : 'disabled'}`}
             href={`/indicators/${indicator.id}/measures`}>
            📐
          </a>
          <span className="tooltiptext">Agregar Medición</span>
        </div>

        <div className='my-tooltip-right'>
          <a className={`btn btn-sm btn-outline-primary mr-1 ${charts ? '' : 'disabled'}`}
             href={`/indicators/${indicator.id}/charts`}>
            📊
          </a>
          <span className="tooltiptext">Gráfica</span>
        </div>

        { indicator.finished &&
          <div className='my-tooltip-right'>
            <a className={`btn btn-sm btn-outline-success mr-1 hover-blank ${restore ? '' : 'disabled'}`}
              href='#'
              onClick={handleRestoreClick}>
              <i className='fas fa-undo-alt text-success'></i>
            </a>
            <span className="tooltiptext">Restaurar</span>
          </div> }

        { !indicator.finished &&
          <div className='my-tooltip-right'>
            <a className={`btn btn-sm btn-outline-success mr-1 hover-blank ${finished ? '' : 'disabled'}`}
              href='#'
              onClick={handleFinishedClick}>
              <i className='fas fa-check text-success'></i>
            </a>
            <span className="tooltiptext">Finalizado</span>
          </div> }

        { indicator.state === null &&
          <div className='my-tooltip-right'>
            <a className={`btn btn-sm btn-outline-danger mr-1 hover-blank ${obsoletes ? '' : 'disabled'}`}
                href='#'
                onClick={handleObsoletes}>
              <i className='far fa-trash-alt text-danger'></i>
            </a>
            <span className="tooltiptext">Enviar a Obsoletos</span>
          </div>
        }

        { indicator.state !== null &&
          <div className='my-tooltip-right'>
            <a className={`btn btn-sm btn-outline-danger mr-1 hover-blank ${deleteButton ? '' : 'disabled'}`}
                href='#'
                onClick={handleDelete}>
              <i className='far fa-trash-alt text-danger'></i>
            </a>
            <span className="tooltiptext">Eliminar indicador</span>
          </div>
        }

        { indicator.state === 'obsolete' &&
          <div className='my-tooltip-right'>
            <a className={`btn btn-sm btn-outline-success mr-1 hover-blank ${restoreTwo ? '' : 'disabled'}`}
                href='#'
                onClick={handleRestore}>
              <i className='fas fa-trash-restore-alt text-success'></i>
            </a>
            <span className="tooltiptext">Restaurar</span>
          </div>
        }

      </div>

    </React.Fragment>
  )
}

export default Buttons