import { Controller } from 'stimulus'
import Chart from 'chart.js'
import tinycolor from 'tinycolor2'
import uniq from 'lodash.uniq'
import get from 'lodash.get'
import indicatorLevelResolver from '../shared/indicatorLevelResolver'
import { lab } from 'd3'

export default class extends Controller {
  connect () {
    let { metrics, trendDirection, years } = this.element.dataset
    years = JSON.parse(years)

    metrics = JSON.parse(metrics)

    const labels = metrics.map(({ date }) => date)
    const numerator = metrics.map(({ numerator }) => Number(Number(numerator).toFixed(2)))
    const denumerator = metrics.map(({ denumerator }) => Number(Number(denumerator).toFixed(2)))
    const sum_numerator = metrics.map(({ sum_numerator }) => Number(Number(sum_numerator).toFixed(2)))
    const colors = metrics.map(({ color }) => color)
    const achievements = metrics.map(({ achievement }) => achievement)

    this.ctx = this.element.getContext('2d')
    this.options = {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            label: 'Numerador',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            borderWidth: 2,
            data: numerator,
            pointBackgroundColor: colors,
            pointBorderColor: colors,
            borderColor: colors
          },
          {
            label: 'Denominador',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            borderWidth: 1,
            data: denumerator,
            borderColor: '#36a2eb'
          },
          {
            label: 'Sumatoria de Numerador',
            pointBorderWidth: 5,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            borderWidth: 1,
            data: sum_numerator,
            borderColor: '#95a2eb'
          }
        ]
      },
      options: {
        tooltips: {
          titleFontFamily: 'Source Sans Pro',
          titleFontSize: 16,
          bodyFontSize: 16,
          xPadding: 10,
          yPadding: 10,
          callbacks: {
            label: (tooltipItem, ___data) => {
              // console.log(tooltipItem)
              // const denumerator = denumerator[tooltipItem.index]
              // const numerator = data[tooltipItem.index]
              // return `Numerador: ${numerator}, Denominador: ${denumerator}`
              return `${tooltipItem.value}`
            }
          }
        },
        animation: {
          easing: 'easeInOutBack'
        },
        legend: {
          display: true,
          position: 'top'
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold',
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 20
            },
            gridLines: {
              drawTicks: false,
              display: false
            }
          }],
          xAxes: [{
            gridLines: {
              zeroLineColor: 'transparent'
            },
            ticks: {
              padding: 20,
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold'
            }
          }]
        }
      },
      plugins: [
        {
          id: 'responsiveGradient',
          afterLayout: (chart, __options) => {
            const gradientStroke = this.ctx.createLinearGradient(
              this.ctx.canvas.width / 2,
              this.ctx.canvas.height,
              this.ctx.canvas.width / 2, 0
            )

            // const gradientFill = this.ctx.createLinearGradient(
            //   this.ctx.canvas.width / 2,
            //   this.ctx.canvas.height,
            //   this.ctx.canvas.width / 2, 0
            // )

            const sortedData = uniq([...numerator].sort((a, b) => a - b))

            const semaphoreIndex = numerator.indexOf(sortedData[0])

            gradientStroke.addColorStop(0, tinycolor(colors[semaphoreIndex]).setAlpha(0.5))
            // gradientFill.addColorStop(0, tinycolor(colors[semaphoreIndex]))

            sortedData.forEach((value) => {
              const colorIndex = numerator.indexOf(value)
              const color = colors[colorIndex]

              const stop = trendDirection === 'increase' ? Math.min(value, 100) / 100 : Math.max(value, 0) / 100

              if (stop >= 0.0 && stop <= 1.0) {
                gradientStroke.addColorStop(stop, tinycolor(color).setAlpha(0.5))
              // gradientFill.addColorStop(stop, tinycolor(color).setAlpha(0.5))
              } else {
                console.error(`Invalid stop ${stop} for ${trendDirection} ${value}`)
              }
            })

            chart.data.datasets[0].borderColor = gradientStroke
            // chart.data.datasets[0].backgroundColor = gradientFill
          }
        }
      ]
    }

    this.chart = new Chart(this.ctx, this.options)

    years.forEach(function(year){
      let yearSum = 0
      let countYear = 0
      let labelsYear
      let denumeratorsum = 0
      let countdenumerator = 0
      let numeratorsum = 0
      let countnumerator = 0
      for (var i = 1; i <= labels.length; i++){
        labelsYear = new Date(labels[i -1]).getFullYear()
        if (labelsYear  === year){
          denumeratorsum += denumerator[i-1]
          countdenumerator += 1
        }
      }
      for (var i = 1; i <= labels.length; i++){
        labelsYear = new Date(labels[i -1]).getFullYear()
        if (labelsYear  === year){
          numeratorsum += numerator[i-1]
          countnumerator += 1
        }
      }
      var table = document.getElementById("tableNumeratorDenumerator")
      if (table){
        var body = table.getElementsByTagName('tbody')[0]
        var row = body.insertRow()
        var cellyear = row.insertCell(0)
        cellyear.innerHTML = year
        cellyear.classList.add('text-center')
        var cellsumNumera = row.insertCell();
        cellsumNumera.innerHTML = numeratorsum.toFixed(2)
        cellsumNumera.classList.add('text-center')
        var cellaverageNumera = row.insertCell();
        cellaverageNumera.innerHTML = (numeratorsum / countnumerator).toFixed(2)
        cellaverageNumera.classList.add('text-center')
        var cellsumDenumera = row.insertCell();
        cellsumDenumera.innerHTML = denumeratorsum.toFixed(2)
        cellsumDenumera.classList.add('text-center')
        var cellaverageDenumera = row.insertCell();
        cellaverageDenumera.innerHTML = (denumeratorsum / countdenumerator).toFixed(2)
        cellaverageDenumera.classList.add('text-center')
      }
    })
  }

  changeType = (type) => {
    this.options.type = type
    this.options.data.datasets[1].type = ['line', 'bar'].includes(type) ? 'line' : undefined

    this.chart.destroy()
    this.chart = new Chart(this.ctx, this.options)
  }
}
