import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['acces']

  connect () {
    this.onChange()
  }

  onChange (e) {
    var value = document.getElementById('role').value;

    if (value === 'admin') {
      document.getElementById("check_permiso").hidden = false;
    } else {
      document.getElementById("check_permiso").hidden = true;
    }
  }

  toggleVisibility(event) {
    const fieldType = event.currentTarget.dataset.field;
    var fieldId = fieldType === "password" ? "password-field" : "confirmation-field";
    var toggleId = fieldType === "password" ? "toggle-password" : "toggle-confirmation";

    this.passwordField = document.getElementById(fieldId);
    this.toggleIcon = document.getElementById(toggleId);
    if (this.passwordField.type === "password") {
      this.passwordField.type = "text";
      this.toggleIcon.classList.remove("fa-eye");
      this.toggleIcon.classList.add("fa-eye-slash");
    } else {
      this.passwordField.type = "password";
      this.toggleIcon.classList.remove("fa-eye-slash");
      this.toggleIcon.classList.add("fa-eye");
    }
  }

  syncPassword(event) {
    if (event.currentTarget.dataset.isnew === "false") return; // Solo aplica en registros nuevos
    const identificationValue = event.currentTarget.value;
    const passwordField = document.getElementById("password-field"); // Busca el campo de contraseña
    passwordField.value = identificationValue; // Sincroniza el valor con contraseña
    const confirmation = document.getElementById("confirmation-field");
    confirmation.value = identificationValue; // Sincroniza el valor con confirnacion de contraseña
    const user = document.getElementById("user-field");
    user.value = identificationValue; // Sincroniza el valor con usuario
  }
}
