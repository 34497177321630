import React from "react";
import { serviceUserLogged } from "./serviceUserLogged";
import axios from "axios";

const UserLoggedContext = React.createContext()

function UserLoggedProvider({children}){
	const {
		loading,
		error,
		userLogged,
		setUserLogged,
	} = serviceUserLogged()

	return(
		<UserLoggedContext.Provider
			value={{
				loading,
				error,
				userLogged,
				setUserLogged
			}}
		>
			{children}
		</UserLoggedContext.Provider>
	)
}

export { UserLoggedContext, UserLoggedProvider }