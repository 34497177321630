import React, { useEffect, useState, useForm } from 'react'
import Select from 'react-select'
import avatar from '../../images/avatar.png'

const AssignmentPanel = ({auditProgramReport, props, employees, prompts}) => {
  const [reviewUsers, setReviewUsers] = useState([]);
  const [reviewUsersAux, setReviewUsersAux] = useState([]);
  const [counterReview, setCounterReview] = useState(0);
  const [approveUsers, setApproveUsers] = useState([]);
  const [approveUsersAux, setApproveUsersAux] = useState([]);
  const [counterApprove, setCounterApprove] = useState(0);
  const [readUsers, setReadUsers] = useState([]);
  const [readUsersAux, setReadUsersAux] = useState([]);
  const [counterRead, setCounterRead] = useState(0);
  const [eraseReviewUsers, setEraseReviewUsers] = useState([]);
  const [eraseReviewUsersAux, setEraseReviewUsersAux] = useState([]);
  const [eraseApproveUsers, setEraseApproveUsers] = useState([]);
  const [eraseApproveUsersAux, setEraseApproveUsersAux] = useState([]);
  const [eraseReadUsers, setEraseReadUsers] = useState([]);
  const [seeAll, setSeeAll] = useState(auditProgramReport?.see_all);
  const [showUsers, setShowUsers] = useState(!auditProgramReport?.see_all);

  const handleCheckboxChange = (e) => {
    setSeeAll(e.target.checked);
    setShowUsers((showUsers) => !showUsers)

    setSendJson({
      audit_program_report: {
        see_all: e.target.checked,
        assignments_attributes,
        reviews_attributes,
      },
      commit: 'submit',
    })

    setReviewJson({
      audit_program_report: {
        see_all: e.target.checked,
        assignments_attributes,
        reviews_attributes,
      },
      commit: 'review',
    })

    setReturnJson({
      audit_program_report: {
        see_all: e.target.checked,
        assignments_attributes,
        reviews_attributes,
      },
      commit: 'return',
    })

    setApproveJson({
      audit_program_report: {
        see_all: e.target.checked,
        assignments_attributes,
        reviews_attributes,
      },
      commit: 'approve',
    })
  }

  const options = employees.map(employee => {
    const [label, value] = employee;
    return { label, value };
  })
  const defaultOption = {
    label: props.name,
    id: props.id
  }
  const [eraseReadUsersAux, setEraseReadUsersAux] = useState([]);
  const [reviewsAux, setReviewsAux] = useState([]);
  const [reviewsVector, setReviewsVector] = useState([]);
  const [reviews_attributes, setReviews] = useState([
    {
      id: auditProgramReport.reviews.length === 1 ? auditProgramReport.reviews[0].id : '',
      reviewable_id: auditProgramReport.assignments[0].assignable_id,
      reviewable_type: 'AuditProgramReport',
      user_id: prompts.current_user_id,
      content: '',
    }
  ]);
  const [assignments_attributes, setVector] = useState([
    {
      id: auditProgramReport.assignments[0].id,
      action: 'produce',
      assignable_id: auditProgramReport.assignments[0].assignable_id,
      assignable_type: 'AuditProgramReport',
      _destroy: false,
    }
  ]);
  const [returnJson, setReturnJson] = useState({ 
    audit_program_report: {
      assignments_attributes,
      reviews_attributes,
    },
    commit: 'return',
  });
  const [sendJson, setSendJson] = useState({ 
    audit_program_report: {
      assignments_attributes,
      reviews_attributes,
    },
    commit: 'submit',
  });
  const [reviewJson, setReviewJson] = useState({ 
    audit_program_report: {
      assignments_attributes,
      reviews_attributes,
    },
    commit: 'review',
  });
  const [approveJson, setApproveJson] = useState({ 
    audit_program_report: {
      assignments_attributes,
      reviews_attributes,
    },
    commit: 'approve',
  });

  useEffect(() => {
    if (auditProgramReport.assignments.length > 1 ) {
      const newReviewUsers = auditProgramReport.assignments.filter((user) => user.action === 'review');
      setReviewUsers(newReviewUsers);
      setReviewUsersAux(newReviewUsers);
      const newApproveUsers = auditProgramReport.assignments.filter((user) => user.action === 'approve');
      setApproveUsers(newApproveUsers);
      setApproveUsersAux(newApproveUsers);
      const newReadUsers = auditProgramReport.assignments.filter((user) => user.action === 'read');
      setReadUsers(newReadUsers);
      setReadUsersAux(newReadUsers);
    }
    if (auditProgramReport.reviews.length >= 1 ) {
      auditProgramReport.reviews.map(review=>{
        const newReviewUserName = review.user_name.label;
        const newDateReview = new Date(review.created_at);
        const currentDate = new Date();
        const diffTime = currentDate - newDateReview;
        const diffHours = Math.ceil(diffTime / (1000*60*60));
        const opciones = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'};
        const newReview = {
          id: review.id,
          reviewable_id: review.reviewable_id,
          reviewable_type: 'AuditProgramReport',
          user_id: review.user_id,
          content: review.content,
          userName: newReviewUserName,
          hours: diffHours,
          date: newDateReview.toLocaleDateString('es-ch', opciones),
        }
        setReviewsVector([...reviewsVector, newReview]);
        reviewsVector.push(newReview);
      });
    }
  }, []);

  const handleClickReview = () => {
    setCounterReview( counterReview + 1 );
    const newUser = {
      user_id: defaultOption.id,
      id: counterReview,
    }
    setReviewUsers([...reviewUsers, newUser]);
  }
  
  const handleClickApprove = () => {
    setCounterApprove( counterApprove + 1 );
    const newUser = {
      user_id: defaultOption.id,
      id: counterApprove,
    }
    setApproveUsers([...approveUsers, newUser]);
  }

  const handleClickRead = () => {
    setCounterRead( counterRead + 1 );
    const newUser = {
      user_id: defaultOption.id,
      id: counterRead,
    }
    setReadUsers([...readUsers, newUser]);
  }
  
  const handleSelectReview = (id) => (e) => {
    const newUser = reviewUsers.map(user => {
      if (user.id === id) {
        return {
          ...user,
          user_id: e.value
        }
      }
      return user
    })
    setReviewUsers(newUser);
  };

  const handleSelectApprove = (id) => (e) => {
    const newUser = approveUsers.map(user => {
      if (user.id === id) {
        return {
          ...user,
          user_id: e.value
        }
      }
      return user
    })
    setApproveUsers(newUser);
  };

  const handleSelectRead = (id) => (e) => {
    const newUser = readUsers.map(user => {
      if (user.id === id) {
        return {
          ...user,
          user_id: e.value
        }
      }
      return user
    })
    setReadUsers(newUser);
  };

  const handleRemoveReview = (e) => {
    const newEraseUsers= reviewUsers.filter((user) => user.user_id === e);
    const newUsers = reviewUsers.filter((user) => user.user_id !== e);
    setEraseReviewUsers([...eraseReviewUsers, newEraseUsers].flat());
    setReviewUsers(newUsers);
  };

  useEffect(() => {
    eraseReviewUsers.map(user2 => {
      reviewUsersAux.map(user =>{
        if (user.user_id === user2.user_id) {
          const newUsers = eraseReviewUsers.filter((user) => user.user_id === user2.user_id);
          setEraseReviewUsersAux([...eraseReviewUsersAux, newUsers].flat(2));
        }
      })
    })
  }, [eraseReviewUsers])

  const handleRemoveApprove = (e) => {
    const newEraseUsers= approveUsers.filter((user) => user.user_id === e);
    const newUsers = approveUsers.filter((user) => user.user_id !== e);
    setEraseApproveUsers([...eraseApproveUsers, newEraseUsers].flat());
    setApproveUsers(newUsers);
  };

  useEffect(() => {
    eraseApproveUsers.map(user2 => {
      approveUsersAux.map(user =>{
        if (user.user_id === user2.user_id) {
          const newUsers = eraseApproveUsers.filter((user) => user.user_id === user2.user_id);
          setEraseApproveUsersAux([...eraseApproveUsersAux, newUsers].flat(2));
        }
      })
    })
  }, [eraseApproveUsers]);
  
  const handleRemoveRead = (e) => {
    const newEraseUsers= readUsers.filter((user) => user.user_id === e);
    const newUsers = readUsers.filter((user) => user.user_id !== e);
    setEraseReadUsers([...eraseReadUsers, newEraseUsers].flat());
    setReadUsers(newUsers);
  };

  useEffect(() => {
    eraseReadUsers.map(user2 => {
      readUsersAux.map(user =>{
        if (user.user_id === user2.user_id) {
          const newUsers = eraseReadUsers.filter((user) => user.user_id === user2.user_id);
          setEraseReadUsersAux([...eraseReadUsersAux, newUsers].flat(2));
        }
      })
    })
  }, [eraseReadUsers]);

  const buildSendJson = () => {
    console.log("seeAll dentro del buildSendJson" + seeAll)
    console.log("holaaa")
    eraseReviewUsersAux.map(user=>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'review',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: 1,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setSendJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
        },
        commit: 'submit',
      })
    })

    reviewUsers.map(user =>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'review',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: false,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setSendJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'submit',
      })
    })

    eraseApproveUsersAux.map(user=>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'approve',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: 1,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setSendJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
        },
        commit: 'submit',
      })
    })

    approveUsers.map(user =>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'approve',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: false,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setSendJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'submit',
      })
    })

    eraseReadUsersAux.map(user=>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'read',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: 1,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setSendJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
        },
        commit: 'submit',
      })
    })

    readUsers.map(user =>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'read',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: false,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setSendJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'submit',
      })
    })

    reviewsAux.map(review=>{
      const newReview = {
        id: '',
        reviewable_id: auditProgramReport.assignments[0].assignable_id,
        reviewable_type: 'AuditProgramReport',
        user_id: review.user_id,
        content: review.content,
      }
      reviews_attributes.splice(0, 1, newReview);
      setSendJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'submit',
      })
    })
  };

  const buildReturnJson = () => {
    console.log('seeAll dentro del buildSendJson', seeAll)
    reviewUsers.map(user =>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'review',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: false,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setReturnJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'return',
      })
    })

    approveUsers.map(user =>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'approve',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: false,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setReturnJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'return',
      })
    })

    readUsers.map(user =>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'read',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: false,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setReturnJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'return',
      })
    })

    reviewsAux.map(review=>{
      const newReview = {
        id: '',
        reviewable_id: auditProgramReport.assignments[0].assignable_id,
        reviewable_type: 'AuditProgramReport',
        user_id: review.user_id,
        content: review.content,
      }
      reviews_attributes.splice(0, 1, newReview);
      setReturnJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'return',
      })
    })
  };

  const buildReviewJson = () => {
    console.log('seeAll dentro del buildSendJson', seeAll)
    reviewUsers.map(user =>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'review',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: false,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setReviewJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'review',
      })
    })

    approveUsers.map(user =>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'approve',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: false,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setReviewJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'review',
      })
    })

    readUsers.map(user =>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'read',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: false,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setReviewJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'review',
      })
    })

    reviewsAux.map(review=>{
      const newReview = {
        id: '',
        reviewable_id: auditProgramReport.assignments[0].assignable_id,
        reviewable_type: 'AuditProgramReport',
        user_id: review.user_id,
        content: review.content,
      }
      reviews_attributes.splice(0, 1, newReview);
      setReviewJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'review',
      })
    })
  };

  const buildApproveJson = () => {
    console.log('seeAll dentro del buildSendJson', seeAll)
    reviewUsers.map(user =>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'review',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: false,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setApproveJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'approve',
      })
    })

    approveUsers.map(user =>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'approve',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: false,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setApproveJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'approve',
      })
    })

    readUsers.map(user =>{
      const newUser = {
        id: typeof(user.assignment_id) === 'string' ? user.assignment_id : '',
        action: 'read',
        assignable_id: auditProgramReport.assignments[0].assignable_id,
        assignable_type: 'AuditProgramReport',
        user_id: user.user_id,
        _destroy: false,
      }
      setVector([...assignments_attributes, newUser]);
      assignments_attributes.push(newUser);
      setApproveJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'approve',
      })
    })

    reviewsAux.map(review=>{
      const newReview = {
        id: '',
        reviewable_id: auditProgramReport.assignments[0].assignable_id,
        reviewable_type: 'AuditProgramReport',
        user_id: review.user_id,
        content: review.content,
      }
      reviews_attributes.splice(0, 1, newReview);
      setApproveJson({
        audit_program_report: {
          see_all: seeAll,
          assignments_attributes,
          reviews_attributes,
        },
        commit: 'approve',
      })
    })
  };

  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

  const handleSubmit = () => {
    buildSendJson();
    fetch(`/api/v2/audit_reports/${auditProgramReport.id}`,{
      method: 'PATCH',
      body: JSON.stringify(sendJson),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-CSRF-Token': csrfToken
      },
    }).then((response)=>{
      return response.json()
    })
    location.reload();
  };

  const handleSubmitReturn = () => {
    buildReturnJson();
    fetch(`/api/v2/audit_reports/${auditProgramReport.id}`,{
      method: 'PATCH',
      body: JSON.stringify(returnJson),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-CSRF-Token': csrfToken
      },
    }).then((response)=>{
      return response.json()
    })
    location.reload();
  };

  const handleSubmitReview = () => {
    buildReviewJson();
    fetch(`/api/v2/audit_reports/${auditProgramReport.id}`,{
      method: 'PATCH',
      body: JSON.stringify(reviewJson),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-CSRF-Token': csrfToken
      },
    }).then((response)=>{
      return response.json()
    })
    location.reload();
  };

  const handleSubmitApprove = () => {
    buildApproveJson();
    fetch(`/api/v2/audit_reports/${auditProgramReport.id}`,{
      method: 'PATCH',
      body: JSON.stringify(approveJson),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-CSRF-Token': csrfToken
      },
    }).then((response)=>{
      return response.json()
    })
    location.reload();
  };

  const handleReviewContent = (e) => {
    const newReview = {
      id: '',
      reviewable_id: auditProgramReport.assignments[0].assignable_id,
      reviewable_type: 'AuditProgramReport',
      user_id: prompts.current_user_id,
      content: e.target.value,
    }
    setReviewsAux([newReview]);
  };

  return (
    <>
      <div className='card card-body mb-2 mx-1 mt-3'>
          <div className="form-row m-0">
            <div className='col-md-3 p-0'>
              <div className='card-header bg-white'>
                <h6>Elabora</h6>
              </div>
              <div className='card-body'>
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <div className='input-group-text bg-white border-0'>
                      <span className='fas fa-user'></span>
                    </div>
                  </div>
                  <Select value={defaultOption} isDisabled={true} />
                </div>
              </div>
            </div>
            <div className='col-md-3 p-0'>
              <div className='card-header bg-white'>
                <h6>Revisa</h6>
              </div>
              <div className='card-body'>   
            {reviewUsers.length > 0 && reviewUsers.map(user => (
              <div key={!!user.assignment_id ? user.assignment_id : user.id}>
                <div className='input-group mb-3'>  
                  <div className='input-group-prepend'>
                    <div className='input-group-text bg-white border-0'>
                      <span className='fas fa-user'></span>
                    </div>
                    </div>
                    <Select 
                      options={options}  
                      defaultValue={!!user.user_name ? user.user_name : defaultOption } 
                      onChange={handleSelectReview(user.id)}
                    />
                    <div className='input-group-append'>
                    <div className='input-group-text bg-white border-0'>
                      <span className='fas fa-times cursor-pointer' onClick={()=>handleRemoveReview(user.user_id)}></span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <button className='btn btn-info btn-sm' onClick={handleClickReview} type='button'>
                <span className='fas fa-plus mr-2' >
                  Agregar
                </span>
            </button>
            </div>
            </div>
            <div className='col-md-3 p-0'>
              <div className='card-header bg-white'>
                <h6>
                  Aprueba
                </h6>
              </div>
              <div className='card-body'>
            {approveUsers.length > 0 && approveUsers.map(user => (
              <div key={!!user.assignment_id ? user.assignment_id : user.id}>
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <div className='input-group-text bg-white border-0'>
                      <span className='fas fa-user'></span>
                    </div>
                  </div>
                  <Select 
                    options={options}  
                    defaultValue={!!user.user_name ? user.user_name : defaultOption } 
                    onChange={handleSelectApprove(user.id)}
                  />
                  <div className='input-group-append'>
                  <div className='input-group-text bg-white border-0'>
                    <span className='fas fa-times cursor-pointer' onClick={()=>handleRemoveApprove(user.user_id)}></span>
                  </div>
                  </div>
                </div>
              </div>
            ))}
              <button className='btn btn-info btn-sm' onClick={handleClickApprove} type='button'>
                <span className='fas fa-plus mr-2'>
                  Agregar
                </span>
              </button>
              </div>
            </div>
            <div className='col-md-3 p-0'>
              <div className='card-header bg-white'>
                <h6>
                  Leer
                </h6>
              </div>
              <div className='card-body'>
                <div className="form-row mb-2">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input ml-3"
                      type="checkbox"
                      checked={seeAll}
                      id="see_all"
                      onChange={handleCheckboxChange}
                    />
                    <label className="custom-control-label mr-3" htmlFor="see_all">
                      Todos
                    </label>
                  </div>
                </div>
            {readUsers.length >= 0 && showUsers && readUsers.map(user=>(
              <div key={!!user.assignment_id ? user.assignment_id : user.id}>
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <div className='input-group-text bg-white border-0'>
                      <span className='fas fa-user'></span>
                    </div>
                  </div>
                  <Select 
                    options={options}  
                    defaultValue={!!user.user_name ? user.user_name : defaultOption } 
                    onChange={handleSelectRead(user.id)}
                  />
                  <div className='input-group-append'>
                  <div className='input-group-text bg-white border-0'>
                    <span className='fas fa-times cursor-pointer' onClick={()=>handleRemoveRead(user.user_id)}></span>
                  </div>
                  </div>
                </div> 
              </div>
            ))}
            {showUsers &&
                <button className='btn btn-info btn-sm' onClick={handleClickRead} type='button'>
                  <span className='fas fa-plus mr-2'>
                    Agregar
                  </span>
                </button>
            }
              </div>
            </div>
          </div>
          <div className='card-header bg-white'>
            Control de Cambios
          </div>
          <div className='card-body'>
            {prompts.change_control_first && (
              <div className='form-group'>
                <textarea className='form-control' placeholder='Escribe tus Observaciones' rows="3" onChange={handleReviewContent}></textarea>
              </div>
            )}
            {prompts.change_control_second && (
              reviewsVector.map(review=>(
                <div className='row mb-2' key={review.id}>
                  <div className='col-1 d-flex justify-content-center p-0'>
                    <img src={avatar} alt="avatar" className='rounded-circle' width='40px' height='40px'  />
                  </div>
                  <div className='col-11 p-0'>
                    <div className='bubble p-2'>
                      <div className='h6 font-weight-bold'>
                        { review.userName}{' · '}{ review.date }{' · '}{'hace ' + review.hours + ' horas' }
                      </div>
                      <div className='h6'>
                        {review.content}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <button type="submit" id='sendAssignments' hidden onClick={handleSubmit}/>
          <button type="submit" id='returnAssignments' hidden onClick={handleSubmitReturn}/>
          <button type="submit" id='reviewAssignments' hidden onClick={handleSubmitReview}/>
          <button type="submit" id='approveAssignments' hidden onClick={handleSubmitApprove}/>
      </div>
    </>
  )
}

export default AssignmentPanel