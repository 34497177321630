import React, { useState, useEffect} from "react";
import { LaboratoriesContext } from "../LaboratoriesContext";
import { InputGroup } from "../../InputGroup";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CollapseTypeLaboratory } from "../CollapseTypeLaboratory";

function EditReport(){
  const {
    setOpenModalEditReport,
    dataLaboratory,
    updateLaboratory
  } = React.useContext(LaboratoriesContext)
  const [address, setAdress] = useState(dataLaboratory?.address || '')
  const [nameCompanyName, setNameCompanyName] = useState(dataLaboratory?.name_company_name || '')
  const [phone, setPhone] = useState(dataLaboratory?.phone || '')
  const [email, setEmail] = useState(dataLaboratory?.email || '')
  const [date, setDate] = useState(
    dataLaboratory?.date ? new Date(dataLaboratory.date) : new Date()
  );
  const [addReportModificationComment, setAddReportModificationComment] = useState(dataLaboratory?.add_report_modification_comment || '')
  const [contactPerson, setContactPerson] = useState(dataLaboratory?.contact_person || '')
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const [descriptionResults, setDescriptionResults] = useState(dataLaboratory?.description_results || '')
  const [declarationConformity, setDeclarationConformity] = useState(dataLaboratory?.declaration_conformity || '')
  const [applications, setApplications] = useState(dataLaboratory?.request_tests || []);
  const [testExecutions, setTestExecutions] = useState(
    dataLaboratory?.test_executions.length > 0 ? dataLaboratory?.test_executions : dataLaboratory?.request_tests.map(request => ({
      id: '',
      type_laboratory_test_id: request.type_laboratory_test_id,
      laboratory_id: request.laboratory_id,
      number_unit: '',
      acceptance_criteria: '',
      uncertainty: '',
      result: '',
      declaration_conformity: true,
      _destroy: false
    }))
  );

  const onUpdate = (e) => {
    e.preventDefault()
    const confirmed = window.confirm('¿Está seguro que desa actualizar y generar un nuevo documento final?')
    if (confirmed){
      setDisabledSubmit(true)
      const dataupdateLaboratory = {
        address: address,
        name_company_name: nameCompanyName,
        phone: phone,
        email: email,
        date: date,
        add_report_modification_comment: addReportModificationComment,
        type: dataLaboratory?.file_report_edit ? 'update_report_two' : 'update_report',
        constact_person: contactPerson,
        description_results: descriptionResults,
        declaration_conformity: declarationConformity,
        test_executions_attributes: testExecutions.map((execution) =>({
          id: execution?.id ? execution.id : '',
          type_laboratory_test_id: execution.type_laboratory_test_id,
          laboratory_id: execution.laboratory_id,
          number_unit: execution.number_unit,
          acceptance_criteria: execution.acceptance_criteria,
          uncertainty: execution.uncertainty,
          result: execution.result,
          declaration_conformity: execution.declaration_conformity
        })),
      }
      setTimeout(() => {
        setDisabledSubmit(false);
      }, 4000);
      updateLaboratory(dataLaboratory.id, dataupdateLaboratory)
    }
  }

  const onCancel = () => {
    setOpenModalEditReport(false);
  }

  const updateNumerUnit = (e, id) => {
    const data = e.target.value
    setTestExecutions(
      testExecutions.map((execution) =>
        execution.type_laboratory_test_id === id 
          ? { ...execution, number_unit: data } 
          : execution
      )
    );
    setAbilitySend(DataComplete)
  }

  const udpateUncertainty = (e, id) =>{
    const data = e.target.value
    setTestExecutions(
      testExecutions.map((execution) =>
        execution.type_laboratory_test_id === id 
          ? { ...execution, uncertainty: data } 
          : execution
      )
    );
    setAbilitySend(DataComplete)
  }

  const udpateResult = (e, id) =>{
    const data = e.target.value
    setTestExecutions(
      testExecutions.map((execution) =>
        execution.type_laboratory_test_id === id 
          ? { ...execution, result: data } 
          : execution
      )
    );
    setAbilitySend(DataComplete)
  }

  const udpateAcceptanceCriteria = (e, id) =>{
    const data = e.target.value
    setTestExecutions(
      testExecutions.map((execution) =>
        execution.type_laboratory_test_id === id 
          ? { ...execution, acceptance_criteria: data } 
          : execution
      )
    );
    setAbilitySend(DataComplete)
  }

  const updateDeclarationConformity = (id, value) => {
    setTestExecutions(
      testExecutions.map((execution) =>
        execution.type_laboratory_test_id === id 
          ? { ...execution, declaration_conformity: value } 
          : execution
      )
    );
    setAbilitySend(DataComplete)
  };
  return(
    <div className="modal fade show" tabIndex="-1" style={{ paddingRight: '4px', display: 'block', backgroundColor: 'rgba(3, 3, 3, 0.5)' }} role="document">
      <form onSubmit={onUpdate}>
        <div className="modal-dialog modal-dialog-scrollable" role="document" style={{ maxWidth: '700px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edición de información de solicitante</h5>
            </div>
            <div className="modal-body">
              <InputGroup iconClass="far fa-file-alt" label="Opiniones e Interpretaciones">
                <textarea
                  placeholder=" Descripción de opiniones e interpretaciones"
                  className="form-control"
                  value={descriptionResults}
                  onChange={(e) => setDescriptionResults(e.target.value)}
                />
              </InputGroup>
              <InputGroup iconClass="far fa-file-alt" label="Declaración de Conformidad.">
                <textarea
                  value={declarationConformity}
                  placeholder="Escribe la Declaración de Conformidad."
                  onChange={(e) => setDeclarationConformity(e.target.value)}
                  className="form-control"
                />
              </InputGroup>
              <div className="container-fluid">
                <h6> Resultados </h6>
                {applications.length > 0 ? (
                  applications.map((application, index) => {
                    // Encuentra el testExecution correspondiente al application actual
                    const matchingTest = testExecutions.find(
                      test => test.type_laboratory_test_id === application.type_laboratory_test_id
                    );

                    return matchingTest ? (
                      <div className="card border" key={application.id}> 
                        <CollapseTypeLaboratory
                          key={application.id}
                          index={index}
                          dataLaboratory={application}
                          testExecution={matchingTest}
                          updateDeclarationConformity={updateDeclarationConformity}
                          updateNumerUnit={updateNumerUnit}
                          udpateAcceptanceCriteria={udpateAcceptanceCriteria}
                          udpateUncertainty={udpateUncertainty}
                          udpateResult={udpateResult}
                        />
                      </div>
                    ) : null;
                  })
                ) : (
                  <p>No hay pruebas seleccionadas.</p>
                )}
              </div>
              <InputGroup iconClass="fas fa-window-restore" label="Razón por modificación del informe">
                <textarea
                  className="form-control"
                  value={addReportModificationComment}
                  onChange={(e) => setAddReportModificationComment(e.target.value)}
                />
              </InputGroup>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-link'
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                className='btn btn-primary mr-1'
                type="submit"
                disabled={disabledSubmit}
              >
                Actualizar Informe
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export { EditReport }