import React from "react";
import axios from "axios";

function serviceUserLogged(){
  const [userLogged, setUserLogged] = React.useState([])
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  React.useState(() => {
    const getData = async () => {
      try{
        const url = "/api/v2/user_logged_to_laboratories";
        const response = await axios.get(`${url}`)
        if (response.status === 200){
          const data = await response.data
          setUserLogged(data)
        } else {
          throw new Error('Network response was not ok.')
        }
      } catch(error){
        setError(error)
      } finally{
        setLoading(false)
      }
    }
    getData()
    return () =>{
      setError(null)
    }
  }, [])
  return {
    loading,
    error,
    userLogged,
    setUserLogged
  }
}

export { serviceUserLogged }