import React, { useContext } from "react";
import { UserLoggedContext } from "./UserLoggedContext";
import { LoadingSpinner } from "../../LoadingSpinner";
import { EmptyRecords } from "../../EmptyRecords";
import { Table } from "./Table";
function AppUI(){
  const {
    loading,
    error,
    userLogged
  } = useContext(UserLoggedContext)

  return(
    <div className="card">
      <div className="card-header d-flex justify-content-between bg-dark text-white mb-0">
        <div className="card-title mb-0 d-flex align-items-center ml-xs-4">
          <a
            href="/admin_laboratories"
          >
            <i className="fas fa-arrow-left"/>
          </a>
          <h5 className="card-title font-weight-bold ml-xs-4">
            Lista de usuarios registrados
          </h5>
        </div>
      </div>
      <div className="card-body p-0">
        {loading && <LoadingSpinner/>}
        {error && <p>Se generó un error :o</p>}
        {!loading && userLogged.length <= 0 && <EmptyRecords/> }
        {!loading && userLogged.length > 0 &&  <Table/>}
      </div>
    </div>
  )
}

export default AppUI