import React from "react";
import { UserLoggedProvider } from "./UserLoggedContext";
import AppUI  from "./AppUI";

function Laboratories() {
  return(
    <UserLoggedProvider>
      <AppUI/>
    </UserLoggedProvider>
  )
}

export default Laboratories