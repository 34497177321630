import React, { useContext } from "react";
import { UserLoggedContext } from "../UserLoggedContext";

function Table(){
	const {
		userLogged
	} = useContext(UserLoggedContext)

	console.log(userLogged)

	return(
		<React.Fragment>
			<table className="table table-bordered table-hover table-sm table-striped">
        <thead>
          <tr>
						<th className="align-middle text-center" style={{width: "10%"}}>Código</th>
						<th className="align-middle text-center" style={{width: "10%"}}>Identificación</th>
						<th className="align-middle text-center" style={{width: "10%"}}>Nombre de compañia</th>
						<th className="align-middle text-center" style={{width: "10%"}}>Persona de Contacto</th>
						<th className="align-middle text-center" style={{width: "10%"}}>Email</th>
					</tr>
				</thead>
				<tbody>
					{userLogged.length > 0 &&
						userLogged.map((user, index) => (
							<React.Fragment key={index}>
								<tr>
									<td className="align-middle text-center">
                    {user.registered_code}
                  </td>
									<td className="align-middle text-center">
                    {user.identification}
                  </td>
									<td className="align-middle text-center">
                    {user.name_company_name}
                  </td>
									<td className="align-middle text-center">
                    {user.contact_person}
                  </td>
									<td className="align-middle text-center">
                    {user.email}
                  </td>
								</tr>
							</React.Fragment>
						))
					}
				</tbody>
			</table>
		</React.Fragment>
	)
}

export { Table }